<template>
  <div class="d-flex flex-grow-0">
    <!--    <v-btn color="white" href="#" icon target="_blank" variant="text">-->
    <!--      <v-icon>mdi-instagram</v-icon>-->
    <!--    </v-btn>-->
    <!--    <v-btn color="white" href="#" icon target="_blank" variant="text">-->
    <!--      <v-icon>mdi-facebook</v-icon>-->
    <!--    </v-btn>-->
    <!--    <v-btn color="white" href="#" icon target="_blank" variant="text">-->
    <!--      <v-icon>mdi-youtube</v-icon>-->
    <!--    </v-btn>-->
    <!--    <v-btn color="white" href="#" icon target="_blank" variant="text">-->
    <!--      <v-icon>mdi-linkedin</v-icon>-->
    <!--    </v-btn>-->
  </div>
</template>
