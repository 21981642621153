<template>
  <app-section id="faq">
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(item, index) in items"
        :key="index"
        eager
        :text="item.content"
        :title="item.title"
      />
    </v-expansion-panels>
  </app-section>
</template>

<script lang="ts" setup>
const items = computed(() => [
  {
    title: 'Czy mogę się umówić na wizytę online?',
    content:
      'Tak, umówisz się na wizytę online. Wystarczy, że wybierzesz odpowiednią usługę i zaznaczysz, że chcesz wizytę online lub ustalisz taki tryb przyjęcia podczas rejestracji telefonicznie lub przez e-mail.',
  },
  {
    title: 'Czy mogę umówić się na wizytę w weekend?',
    content:
      'Generalnie nie pracujemy w weekendy i święta. W przypadku pilnej potrzeby konsultacji lub potrzeby wizyty środowiskowej w domu pacjenta - prosimy o kontakt telefoniczny.',
  },
  {
    title: 'Jak umówić się na wizytę do psychiatry?',
    content:
      'Najłatwiej i najszybciej umówisz się przez stronę ZnanyLekarz.pl znajdując tam profil odpowiedniego specjalisty. W przypadku trudności - zawsze możesz skorzystać z naszego telefonu kontaktowego, żeby uzyskać pomoc.',
  },
  {
    title: 'Jak umówić się na psychoterapię lub konsultację psychologiczną?',
    content:
      'Najłatwiej umawiać się za pomocą naszego numeru telefonu kontaktowego lub kontaktując się z konkretnym psychologiem/psychoterapeutą - telefonicznie lub przez e-mail.',
  },
  {
    title: 'Do kogo umawiać się najpierw - do psychologa czy psychiatry?',
    content:
      'Generalnie to zależy od typu potrzeby lub problemu. Bardziej szczegółowo omawiamy to zagadnienie we wpisie na naszym blogu. Jeśli nadal masz wątpliwości - zacznij od konsultacji psychiatrycznej.',
  },
  {
    title:
      'Potrzebuję diagnostyki w kierunku ADHD - do kogo umówić się na badanie?',
    content:
      'Zazwyczaj najlepiej zacząć od wizyty psychiatrycznej. Jeżeli dotychczas żadna diagnostyka w kierunku ADHD nie była prowadzona - lekarz zaproponuje odpowiedni pakiet diagnostyczny; jeżeli natomiast część diagnostyki już jest zrobiona w innej placówce - lekarz uwzględni to w planie diagnostycznym i ewentualnie zaproponuje odpowiednie uzupełnienie lub postawi rozpoznanie w oparciu o dostarczone raporty diagnostyczne z innych porani.',
  },
  {
    title: 'Jak przebiega proces płatności za usługi?',
    content:
      'Opłata za konsultacje psychiatryczne za wizyty pierwszorazowe pobierana jest z góry podczas rezerwacji konkretnego terminu w serwisie ZnanyLekarz.pl lub poprzez link do płatności wysyłany po rezerwacji terminu telefonicznie. W przypadku konsultacji kolejnych lub podczas opłat za diagnostykę psychologiczną - istnieje możliwość zapłaty na miejscu gotówką lub kartą płatniczą lub przelewem po otrzymaniu faktury.',
  },
  {
    title: 'Czy płatność za usługę mogę podzielić na raty?',
    content:
      'Opłat za konsultacje psychiatryczne lub konsultacje psychologiczne pobierane są zawsze w całości i nie ma możliwości dzielenia ich na raty. Opłaty z tytułu pakietów diagnostycznych są dzielone na 2 raty - pierwsza opłacana jest podczas wykonywania badań/testów, a druga po omówieniu i przekazaniu końcowego raportu diagnostycznego.',
  },
])
</script>
